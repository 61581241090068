import React from 'react';
import { graphql, PageProps } from 'gatsby';

import NewsFeaturedArticle from '../../components/NewsFeaturedArticle/NewsFeaturedArticle';
import GuideBlocks from '../../components/GuideBlocks/GuideBlocks';
import { IArticleExcerpt } from '../../components/ContentTypes/Article/Article.def';
import PageHeader from '../../components/PageHeader/PageHeader';
import {
  getPlaceholderGatsbyImg,
  getArticleExcerptFromWpPost,
  getFeaturedImageDataFromWpPost,
  getImageFromWpImage,
} from '../../utils';
import PaginationV2 from '../../components/PaginationV2/PaginationV2';
import Seo from '../../components/Seo';
import { HT_PAGE_CATEGORY_ARCHIVE_PAGE, MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface NewsCategoryTemplateProps {
  category: Queries.WpCategory;
  posts: Queries.WpPostConnection;
}

const NewsCategoryTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<NewsCategoryTemplateProps>) => {
  const {
    category: { templateForCategories: fieldsForCategory },
    posts,
  } = data;
  const title = 'Latest News';
  const pageHeader = {
    title,
    breadCrumbs: [
      {
        link: '/',
        text: 'Home',
      },
    ],
  };
  const featuredArticleData = fieldsForCategory?.upgpTemplateForCategoriesFeaturedPost;
  let featuredPostLink: string;
  let featuredArticle: IArticleExcerpt | null = null;
  const latestArticles = posts?.nodes;
  let articles = null;
  if (featuredArticleData) {
    featuredPostLink = featuredArticleData.uri ? 'news/' + featuredArticleData.uri : '#';
    featuredArticle = {
      thumbnail: {
        alt: featuredArticleData?.featuredImage?.node?.altText || '',
        src: featuredArticleData?.featuredImage?.node?.sourceUrl || getPlaceholderGatsbyImg(),
      },
      date: featuredArticleData?.dateGmt || '',
      title: featuredArticleData?.title || '',
      link: featuredPostLink,
      excerpt: featuredArticleData?.excerpt || '',
      moreLink: {
        link: featuredPostLink,
        text: 'LEARN MORE',
      },
      isFeaturedArticle: true,
      author: {
        link: featuredArticleData?.author?.node?.uri || '#',
        text: featuredArticleData?.author?.node?.name || '',
      },
    };
    articles = [...latestArticles]
      .filter((a) => a.link !== featuredPostLink)
      .map((wpArticle: Queries.WpPost) => getArticleExcerptFromWpPost(wpArticle))
      .map((article) => ({
        ...article,
        thumbnail: {
          ...article.thumbnail,
          src: article.thumbnail.src,
        },
      }));
  } else {
    const firstArticle = latestArticles[0];
    featuredPostLink = firstArticle?.uri || '#';
    featuredArticle = {
      thumbnail: getImageFromWpImage(firstArticle?.featuredImage?.node),
      date: firstArticle?.dateGmt || '',
      title: firstArticle?.title || '',
      link: featuredPostLink,
      excerpt: firstArticle?.excerpt || '',
      moreLink: {
        link: featuredPostLink,
        text: 'LEARN MORE',
      },
      isFeaturedArticle: true,
      author: {
        link: firstArticle?.author?.node?.uri || '#',
        text: firstArticle?.author?.node?.name || '',
      },
    };
    articles = [...latestArticles]
      .slice(1)
      .map((wpArticle: Queries.WpPost) => getArticleExcerptFromWpPost(wpArticle))
      .map((article) => ({
        ...article,
        thumbnail: {
          ...article.thumbnail,
          src: article.thumbnail.src,
        },
      }));
  }
  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;

  return (
    <HTPageWrapper category={"News, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE} title={title} location={location}>
      <PageHeader {...pageHeader} />
      <NewsFeaturedArticle article={featuredArticle} />
      <GuideBlocks articles={articles} variant="columns" />
      {totalNumberOfPages > 1 && (
        <PaginationV2
          numberOfPages={totalNumberOfPages}
          currentPageIndex={0}
          pageSize={MAX_NUMBER_OF_POSTS_PER_PAGE}
          basePath="/news"
        />
      )}
    </HTPageWrapper>
  );
};

export default NewsCategoryTemplate;

export const Head = ({ data }: PageProps<NewsCategoryTemplateProps>) => {
  const { seo, templateForCategories } = data.category;
  const featuredImageData =
    templateForCategories && templateForCategories.upgpTemplateForCategoriesFeaturedPost
      ? getFeaturedImageDataFromWpPost(templateForCategories.upgpTemplateForCategoriesFeaturedPost)
      : getFeaturedImageDataFromWpPost(data?.posts?.nodes[0]);
  return (
    <Seo
      wpSeo={seo}
      featuredImageData={featuredImageData}
      isNewsListingPage={true}
      isArchivePage={true}
      archiveName={'News'}
      uri={data?.category?.uri}
    />
  );
};

export const query = graphql`
  query NewsCategoryWPosts($id: String, $name: String, $postToExcludeId: Int, $numPosts: Int) {
    category: wpCategory(id: { eq: $id }) {
      uri
      templateForCategories {
        upgpTemplateForCategoriesFeaturedPost {
          ... on WpPost {
            featuredImage {
              node {
                caption
                width
                height
                sourceUrl
                altText
              }
            }
            title
            uri
            dateGmt
            excerpt
            author {
              node {
                uri
                name
              }
            }
          }
        }
      }
      seo {
        title
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          height
          sourceUrl
          width
          mediaType
        }
        opengraphType
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphPublisher
        opengraphModifiedTime
        schema {
          raw
        }
      }
    }
    posts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: $name } } } }
        databaseId: { nin: [$postToExcludeId] }
      }
      sort: { date: DESC }
      limit: $numPosts
    ) {
      nodes {
        id
        title
        uri
        dateGmt
        excerpt
        author {
          node {
            uri
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
            altText
            width
            height
            caption
          }
        }
      }
    }
  }
`;
