import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IPaginationV2 } from './PaginationV2.def';
import { removeTrailingSlash } from '../../utils';
import { getNeighbors } from '../../utils/helpers/pagination';
import { MIN_PAGES_FOR_SEPARATOR, SEPARATOR_THRESHOLD } from '../../constants';

const PaginationV2: FC<IPaginationV2> = ({
  numberOfPages,
  total,
  currentPageIndex,
  pageSize,
  basePath,
  setCurrentPageIndex,
}) => {
  let basePathNoTrailingSlash = '';
  let isClientSidePagination = true;
  if (basePath) {
    basePathNoTrailingSlash = removeTrailingSlash(basePath);
    isClientSidePagination = false;
  }

  let pages = 1;

  if (numberOfPages) {
    pages = numberOfPages;
  }

  if (total && !numberOfPages) {
    pages = Math.ceil(total / pageSize);
  }

  const lastIndex = pages - 1;
  const showFirstSeparator =
    currentPageIndex > SEPARATOR_THRESHOLD && pages >= MIN_PAGES_FOR_SEPARATOR;
  const showLastSeparator =
    lastIndex - currentPageIndex > SEPARATOR_THRESHOLD && pages >= MIN_PAGES_FOR_SEPARATOR;
  const neighbors = getNeighbors(currentPageIndex, lastIndex);

  const handlePrevious = () => {
    if (setCurrentPageIndex) setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleNext = () => {
    if (setCurrentPageIndex) setCurrentPageIndex(currentPageIndex + 1);
  };

  const renderPreviousLink = () => {
    const isFirstPage = currentPageIndex === 0;
    if (isClientSidePagination || isFirstPage) {
      return (
        <button disabled={isFirstPage} onClick={handlePrevious} className="prev">
          <span>PREVIOUS</span>
        </button>
      );
    }

    return (
      <Link
        rel="prev"
        aria-disabled={isFirstPage}
        to={`${basePathNoTrailingSlash}/page/${currentPageIndex}/`}
        className="prev"
      >
        <span>PREVIOUS</span>
      </Link>
    );
  };

  const renderFirstPageLink = () => {
    const firstPage = 1;
    if (isClientSidePagination) {
      return (
        <button
          onClick={() => {
            if (setCurrentPageIndex) setCurrentPageIndex(0);
          }}
          aria-label={`Page ${firstPage}`}
          title={`Page ${firstPage}`}
          key={0}
          className={0 === currentPageIndex ? 'active disabled' : ''}
        >
          {firstPage}
        </button>
      );
    }
    return (
      <Link
        to={basePathNoTrailingSlash}
        key={0}
        aria-label={`Page ${firstPage}`}
        title={`Page ${firstPage}`}
        className={0 === currentPageIndex ? 'active disabled' : ''}
      >
        {firstPage}
      </Link>
    );
  };

  const renderLastPageLink = () => {
    const lastPage = lastIndex + 1;
    if (isClientSidePagination) {
      return (
        <button
          onClick={() => {
            if (setCurrentPageIndex) setCurrentPageIndex(lastIndex);
          }}
          aria-label={`Page ${lastPage}`}
          title={`Page ${lastPage}`}
          key={lastIndex}
          className={lastIndex === currentPageIndex ? 'active disabled' : ''}
        >
          {lastPage}
        </button>
      );
    }
    return (
      <Link
        to={`${basePathNoTrailingSlash}/page/${lastPage}/`}
        key={lastIndex}
        aria-label={`Page ${lastPage}`}
        title={`Page ${lastPage}`}
        className={lastIndex === currentPageIndex ? 'active disabled' : ''}
      >
        {lastPage}
      </Link>
    );
  };

  const renderNextLink = () => {
    const isLastPage = currentPageIndex === lastIndex;
    if (isClientSidePagination || isLastPage) {
      return (
        <button disabled={isLastPage} onClick={handleNext} className="next">
          <span>NEXT</span>
        </button>
      );
    }

    return (
      <Link
        rel="next"
        aria-disabled={isLastPage}
        to={`${basePathNoTrailingSlash}/page/${currentPageIndex + 2}/`}
        className="next"
      >
        <span>NEXT</span>
      </Link>
    );
  };

  return (
    <div className="container">
      <div className="articles pagination">
        {renderPreviousLink()}
        <div className="pages">
          {renderFirstPageLink()}
          {showFirstSeparator && <p>...</p>}
          {neighbors
            .filter((page) => page > 0 && page < lastIndex)
            .map((page) => {
              const currentPage = page + 1;
              if (isClientSidePagination) {
                return (
                  <button
                    onClick={() => {
                      if (setCurrentPageIndex) setCurrentPageIndex(page);
                    }}
                    aria-label={`Page ${currentPage}`}
                    title={`Page ${currentPage}`}
                    key={page}
                    className={page === currentPageIndex ? 'active disabled' : ''}
                  >
                    {currentPage}
                  </button>
                );
              }
              return (
                <Link
                  to={
                    page === 0
                      ? `${basePathNoTrailingSlash}/`
                      : `${basePathNoTrailingSlash}/page/${currentPage}/`
                  }
                  key={page}
                  aria-label={`Page ${currentPage}`}
                  title={`Page ${currentPage}`}
                  className={page === currentPageIndex ? 'active disabled' : ''}
                >
                  {currentPage}
                </Link>
              );
            })}
          {showLastSeparator && <p>...</p>}
          {renderLastPageLink()}
        </div>
        {renderNextLink()}
      </div>
    </div>
  );
};

export default PaginationV2;
