import { PAGINATION_NEIGHBORS } from '../../constants';

/**
 * Get a range of neighboring indices around a given index, constrained by a maximum index.
 *
 * @param {number} index - The current index.
 * @param {number} maxIndex - The maximum index value.
 * @returns {number[]} An array of neighboring indices around the given index.
 */
export const getNeighbors = (index: number, maxIndex: number): number[] => {
  const minIndex = index - PAGINATION_NEIGHBORS;
  const maxIndexToShow = Math.min(index + PAGINATION_NEIGHBORS, maxIndex);

  return range(minIndex, maxIndexToShow, 2 * PAGINATION_NEIGHBORS + 1);
};

/**
 * Generates an array of numbers within a specified range and up to a specified limit.
 *
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {number} limit - The maximum number of elements in the resulting array.
 * @returns {number[]} An array of numbers within the specified range and up to the specified limit.
 */
const range = (min: number, max: number, limit: number): number[] => {
  let rangeArray = [];
  for (let i = min; i <= max && rangeArray.length < limit; i++) {
    rangeArray.push(i);
  }

  return rangeArray;
};
